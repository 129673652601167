
import { TextGeometry } from 'three/examples/jsm/geometries/TextGeometry.js'
import Experience from '../../Experience'
import * as THREE from "three"
import CustomText from './CustomText'
import gsap from 'gsap'
import allCitations from "./AllCitations.json"
import EventEmitter from '../../Utils/EventEmitter'



export default class TextGenerator extends EventEmitter {
    constructor() {

        super()
        this.experience = new Experience()
        this.world = this.experience.world
        this.sounds = this.experience.world.sounds

        this.textParam = {
            font: this.experience.resources.items["Helvetica"],
            size: 0.5,
            height: 0.1,
            curveSegments: 12,
            bevelEnabled: true,
            bevelThickness: 0.00,
            bevelSize: 0.00,
            bevelOffset: 0,
            bevelSegments: 5
        }

        this.shuffleArray(allCitations)
        // console.log("all citations = ", allCitations)
        allCitations.push({
            id: "9999",
            text: "Félicitations, tu es la première personne à avoir regardé toutes les citations. Envoie un message à @mrdreamax pour ajouter la citation de ton choix au site :)"
        })

        this.allCitations = allCitations


        this.currentCitationIndex = 0
        this.charInbetweenSpace = 0.05
        this.spaceCharacterSize = 0.15
        this.objScale = 1

        this.currentCitation = new CustomText("Le temps, c’est la vie. Quand tu perds ton **temps**, tu perds ta **vie**.")
        this.experience.scene.add(this.currentCitation.instance)


        // this.currentCitation.appear()


        // this.simpleText()

    }

    shuffleArray(array) {
        for (let i = array.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            const temp = array[i];
            array[i] = array[j];
            array[j] = temp;
        }
    }

    skip()
    {
        if (!this.currentAnim)
            return
        this.currentAnim.progress(1)
        this.experience.world.sounds.skipWhoosh()
    }


    nextCitation() {
        console.log("init anim")
        this.trigger("nextCitation")
        let currentZoffest = this.world.zOffset

        this.experience.world.sounds.launchWhoosh()

        const tl = gsap.timeline({
            onComplete: () => {
                // this.currentCitation.destroy()
                this.currentCitation.reGenerate(
                    this.allCitations[ 
                        Math.min(this.currentCitationIndex++, this.allCitations.length - 1 ) 
                    ].text)
                this.experience.scene.add(this.currentCitation.instance)
                setTimeout(() => {
                    this.currentCitation.appear()
                }, 500);
                tl.kill()
            },
            onUpdate: () => {
                this.currentCitation.instance.position.z = this.world.zOffset - currentZoffest
            }
        })
        tl.to(this.world, {
            zOffset: this.world.zOffset - 30,
            duration: 0.5,
            ease: "power1.inOut"
        })
        tl.to(this.world, {
            zOffset: this.world.zOffset + 200,
            duration: 2,
            ease: "power2.inOut"
        })
        this.currentAnim = tl
    }

    simpleText() {

        const textGeometry = new TextGeometry(
            "Le temps, c'est la vie\ntest",
            this.textParam
        )

        textGeometry.computeBoundingBox()
        textGeometry.translate(
            - textGeometry.boundingBox.max.x * 0.5,
            0,
            - textGeometry.boundingBox.max.z * 0.5
        )


        this.textSimpleMesh = new THREE.Mesh(textGeometry)
        this.experience.scene.add(this.textSimpleMesh)


    }






}