import EventEmitter from './EventEmitter.js'
import Stats from 'stats.js'
import Experience from '../Experience.js'

export default class Time extends EventEmitter
{
    constructor()
    {
        super()

        // Setup
        this.experience = new Experience()
        this.start = Date.now()
        this.current = this.start
        this.elapsed = 0
        this.delta = 16
        if (this.experience.debug.active)
        {
            this.stats = new Stats()
            this.stats.showPanel(0) // 0: fps, 1: ms, 2: mb, 3+: custom
            document.body.appendChild(this.stats.dom)
        }


        window.requestAnimationFrame(() =>
        {
            this.tick()
        })
    }

    resetTime()
    {
        this.start = Date.now()
        this.current = this.start
        this.elapsed = 0
        this.delta = 16
    }

    tick()
    {
        if (this.stats)
            this.stats.begin()
        const currentTime = Date.now()
        this.delta = currentTime - this.current
        this.current = currentTime
        this.elapsed = this.current - this.start
        this.elapsedSeconds = this.elapsed * 0.001

        this.trigger('tick')

        window.requestAnimationFrame(() =>
        {
            this.tick()
        })
        if (this.stats)
            this.stats.end()
    }
}