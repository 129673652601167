import gsap from "gsap";
import Experience from "../Experience";
import EventEmitter from "../Utils/EventEmitter";





export default class LoadingScreen {

    constructor() {

        this.experience = new Experience()
        this.loadingCompleted = false
        this.hasClicked = false

        const toBeFilled = document.querySelectorAll(".to-be-filled")
        // console.log(toBeFilled)

        this.experience.resources.on("loadProgress", (loadRatio) => {
            // console.log("load progress = ", loadRatio)
            for (const elem of toBeFilled) {
                elem.style.clipPath = `inset( ${100 - loadRatio * 100}% 0px 0px 0px )`

            }
            if (loadRatio == 1)
                this.disolveLoadingScreen()
        })
    }

    disolveBeginScreen() {
        this.hasClicked = true
        gsap.to(".start-container", {
            blur: 120,
            opacity: 0,
            duration: 1.3,
            ease: "power2.inOut",
            onComplete: () => {
                document.querySelector(".loading-screen").remove()
            }
        })

        if (this.handIdleAnim)
            this.handIdleAnim.kill()
        gsap.to("#hand-anim-2",
            {
                opacity: 0,
                scale: 1.4,
                blur: 10,
                x: 10,
                y: 10,
                duration: 0.7,
                ease: "power1.inOut",
                overwrite: true
            })

        this.experience.intro.runIntro()
    }

    disolveLoadingScreen() {
        const tl = gsap.timeline({
            delay: 1,
            // onComplete: () => {
            //     document.querySelector(".loading-screen").remove()
            // }
        })
        tl.to(".spinning-loader", {
            opacity: 0,
        })
        tl.to(".loader-wrapper", {
            scale: 0.65,
            y: 20,
            opacity: 0,
            duration: 0.8,
            ease: "power2.inOut",
            // onStart: () => {
            //     setTimeout(() => {
            //         this.experience.intro.runIntro()
            //     }, 2000);
            // }
        })
        tl.fromTo(".start-container", 
        {
            opacity: 0,
            y: 20,
            scale: 0.65,
            blur: 0
        },
        {
            opacity: 1,
            scale: 1,
            y: 0,
            duration: 0.3,
            ease: "sine.inOut",
            onComplete: () => {
                this.loadingCompleted = true
                this.experience.world.citationCore.phase = "loadingScreen"
                this.createHandleAnim()
            }
        }, "-=0.3")
        tl.fromTo("#hand-anim-2",
            {
                opacity: 0,
                scale: 1.4,
                x: 10,
                y: 10,
                blur: 10,
            },
            {
                opacity: 1,
                scale: 1,
                blur: 0,
                x: 0,
                y: 0,
                duration: 0.7,
                ease: "power1.inOut",
                onComplete: () => {
                    if (!this.hasClicked)
                        this.handIdleAnim.play()
                }
            })

    }


    createHandleAnim() {
        const handTlIdle = gsap.timeline({
            repeat: -1,
            yoyo: true
        })
        handTlIdle.pause()
        handTlIdle.fromTo("#hand-anim-2",
            {
                scale: 1,
                x: 0,
                y: 0,
                blur: 0
            },
            {
                keyframes: [
                    {
                        scale: 1.2,
                        x: 10,
                        y: 10,
                        duration: 0.5,
                        ease: "power1.inOut"
                    },
                    {
                        duration: 0.5,
                        delay: -0.5,
                        blur: 10,
                        ease: "power2.in"
                    }
                ]

            })

        if (this.handIdleAnim)
            this.handIdleAnim.kill()
        this.handIdleAnim = handTlIdle
    }

}