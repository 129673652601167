

import * as THREE from "three"
import Experience from "../../Experience"
import vertexFogs from "../Shaders/vertex_fogs.glsl"
import fragmentFogs from "../Shaders/fragment_fogs.glsl"
import gsap from "gsap"


export default class Fogs
{
    constructor()
    {

        const param = {
            particleNum: 200,
            particleSize: 80000,
            zSpread: 500
        }

        


        this.experience = new Experience()
        console.log("camera fov = ", this.experience.camera.instance.fov)
        param.particleSize *= this.experience.sizes.pixelRatio * 35 / this.experience.camera.instance.fov

        this.geometry = new THREE.BufferGeometry()
        const positions = new Float32Array(param.particleNum * 3)
        const aScale = new Float32Array(param.particleNum * 1)
        const aTextureDir = new Float32Array(param.particleNum * 2)
        for (let i = 0; i < param.particleNum; i++)
        {
            const i3 = i * 3
            const i2 = i * 2

            const theta = Math.random() * Math.PI * 2
            positions[i3] = Math.sin(theta) * (12.3 + Math.random() * 40)
            positions[i3 + 1] = Math.cos(theta) * (12.3 + Math.random() * 40)
            positions[i3 + 2] = (Math.random() - 0.9) * param.zSpread
            aScale[i] = Math.random() * 0.3 + 0.7
            
            
            aTextureDir[i2] = Math.sin(theta)
            aTextureDir[i2 + 1] = Math.cos(theta)
        }

        this.geometry.setAttribute('position', new THREE.BufferAttribute(positions, 3))
        this.geometry.setAttribute('aScale', new THREE.BufferAttribute(aScale, 1))
        this.geometry.setAttribute('aTextureDir', new THREE.BufferAttribute(aTextureDir, 2))
        // this.material = new THREE.PointsMaterial({
        //     size: param.particleSize,
        //     sizeAttenuation: true,
        //     transparent: true,
        //     depthWrite: false,
        //     map: this.experience.resources.items["smoke1"]
        // })

        this.material = new THREE.ShaderMaterial({
            // size: param.particleSize,
            // sizeAttenuation: true,
            // transparent: true,
            // depthWrite: false,
            // map: this.experience.resources.items["smoke1"],
            vertexShader: vertexFogs,
            fragmentShader: fragmentFogs,
            transparent: true,
            depthWrite: false,
            uniforms: {
                uLightningPos: {value: new THREE.Vector3(
                    -10, -10, 0
                )},
                uOffsetZ: {value: 0},
                uZspeed: {value: 0},
                uLightning: {value: 0},
                uZspread: { value: param.zSpread},
                uTime: { value: 0 },
                uNoiseTexture: {value: this.experience.resources.items["noiseTexture"]},
                uTexture: {value: this.experience.resources.items["smoke1"]},
                uSize: {value: param.particleSize},
            }
        })



        this.thunder(true)

        this.mesh = new THREE.Points(this.geometry, this.material)
        this.experience.scene.add(this.mesh)
    }


    thunder(isRepeated)
    {
        if (isRepeated)
        setTimeout(() => {
            this.thunder(true)
        }, Math.random() * 3000 + 2000);

        const theta = Math.PI * 2 * Math.random()
        this.material.uniforms.uLightningPos.value = new THREE.Vector3(
            Math.sin(theta) * 15,
            Math.cos(theta) * 15,
            0
        )
        const tl = gsap.timeline({
            onComplete: () => {tl.kill()}
        })
        for (let i = 0; i < Math.floor(Math.random() * 6) + 8 ; i++)
        {
            tl.to(this.material.uniforms.uLightning, {
                value: Math.random() * 1,
                duration: 0.03
            })
        }
        tl.to(this.material.uniforms.uLightning, {
            value: 0,
            duration: 0.3,
        })

    }

    update()
    {
        this.material.uniforms.uOffsetZ.value = this.experience.world.zOffset
        this.material.uniforms.uTime.value = this.experience.time.elapsed * 0.0001
    }
}