import Experience from "../../Experience";
import Raycaster from "./Raycaster";
import TextGenerator from "./TextGenerator";
import SplitType from 'split-type'
import gsap from "gsap";



export default class CitationCore {

    constructor() {

        this.experience = new Experience()
        this.camera = this.experience.camera
        this.world = this.experience.world
        // this.raycaster = new Raycaster()

        this.textGenerator = new TextGenerator()
        this.inCitationWriting = false
        this.tapToSkipText = this.initTapToSkip()
        this.hasTapOnceIdle = false
        this.tapToConfirmShowed = false
        this.tapShowTimeout = 10
        this.isTapToSkipDisplayed = false
        this.textGenerator.currentCitation.on("appearFinished", () => { this.onAppearFinished() })
        this.textGenerator.currentCitation.on("startPlaying", () => { this.onStartPlaying() })
        this.textGenerator.on("nextCitation", () => { this.onNextCitation() })
        this.displayTimeoutFunc = null
        this.phase = ""

        this.initSkipContainer()

        this.initEventControl()
    }


    initSkipContainer() {
        gsap.to(this.tapToSkipText.chars, { opacity: 0, duration: 0 })
        gsap.to("#hand-anim", { opacity: 0, duration: 0 })

        document.querySelector(".skip-container").classList.remove("hidden")

    }

    onStartPlaying() {
        this.phase = "citationPlaying"
    }

    onAppearFinished() {
        this.phase = "idle"
        if (!this.hasTapOnceIdle) {
            clearTimeout(this.displayTimeoutFunc)
            this.displayTimeoutFunc = setTimeout(() => {
                // this.hasTapOnceIdle = true
                this.displayTapToSkip()
            }, 1500);
        }
        else {
            clearTimeout(this.displayTimeoutFunc)
            this.displayTimeoutFunc = setTimeout(() => {
                this.displayTapToSkip()
            }, 1000 * this.tapShowTimeout);
        }
    }

    onNextCitation() {
        this.phase = "transitionToNext"
        this.hideTapToSkip()
    }

    createHandleAnim() {
        const handTlIdle = gsap.timeline({
            repeat: -1,
            yoyo: true
        })
        handTlIdle.fromTo("#hand-anim",
            {
                opacity: 1,
                scale: 1,
                x: 0,
                y: 0,
                blur: 0
            },
            {
                keyframes: [
                    {
                        scale: 1.2,
                        x: 10,
                        y: 10,
                        duration: 0.5,
                        ease: "power1.inOut"
                    },
                    {
                        duration: 0.5,
                        delay: -0.5,
                        blur: 10,
                        ease: "power2.in"
                    }
                ]

            })

        if (this.handIdleAnim)
            this.handIdleAnim.kill()
        this.handIdleAnim = handTlIdle
    }

    initTapToSkip() {
        const text = new SplitType("#tap-to-skip")



        return text
    }


    displayTapToSkip() {
        if (this.isTapToSkipDisplayed)
            return
        this.isTapToSkipDisplayed = true
        clearTimeout(this.displayTimeoutFunc)
        this.tapToConfirmShowed = true
        // document.querySelector(".skip-container").classList.remove("hidden")
        gsap.fromTo(this.tapToSkipText.chars,
            {
                opacity: 0,
                scale: 1.4,
                translateY: -0
            },
            {
                opacity: 1,
                scale: 1,
                translateY: 0,
                duration: 0.8,
                stagger: {
                    each: 0.02,
                    from: "random",
                },
                ease: "power3.inOut"

            })

        gsap.fromTo("#hand-anim",
            {
                opacity: 0,
                scale: 1.4,
                x: 10,
                y: 10,
                blur: 10
            },
            {
                opacity: 1,
                scale: 1,
                blur: 0,
                x: 0,
                y: 0,
                duration: 0.7,
                ease: "power1.inOut",
                onComplete: () => {
                    this.createHandleAnim()
                }
            })
    }

    hideTapToSkip() {
        if (!this.isTapToSkipDisplayed)
            return
        this.isTapToSkipDisplayed = false
        clearTimeout(this.displayTimeoutFunc)
        this.tapToConfirmShowed = false
        gsap.fromTo(this.tapToSkipText.chars,
            {
                opacity: 1,
                scale: 1,
            },
            {
                opacity: 0,
                scale: 1.4,
                duration: 0.8,
                stagger: {
                    each: 0.02,
                    from: "random",
                },
                ease: "power3.inOut",
                // onComplete: () => {
                //     document.querySelector(".skip-container").classList.add("hidden")
                // }

            })

        if (this.handIdleAnim)
            this.handIdleAnim.kill()
        gsap.to("#hand-anim",
            {
                opacity: 0,
                scale: 1.4,
                blur: 10,
                x: 10,
                y: 10,
                duration: 0.7,
                ease: "power1.inOut",
            })
    }


    onInput(evt) {
        evt.preventDefault()
        if (this.debounce)
            return
        this.debounce = true
        setTimeout(() => {
            this.debounce = false
        }, 100);
        clearTimeout(this.displayTimeoutFunc)
        if (this.phase == "intro") {
            if (!this.tapToConfirmShowed) {
                this.displayTapToSkip()
            }
            else {
                console.log("skipping intro noramlly ...")
                this.experience.intro.skipIntro()
                //skip intro
            }
        }
        else if (this.phase == "loadingScreen") {
            this.experience.intro.loadingScreen.disolveBeginScreen()
            this.experience.world.sounds.enableSounds()
        }
        else if (this.phase == "citationPlaying") {
            this.textGenerator.currentCitation.skip()
        }
        else if (this.phase == "transitionToNext") {
            this.textGenerator.skip()
        }
        else if (this.phase == "idle") {
            if (!this.hasTapOnceIdle) {
                this.hasTapOnceIdle = true
            }

            this.textGenerator.nextCitation()

        }

        if (this.tapToConfirmShowed) {

        }

    }


    initEventControl() {

        const infoScreen = document.querySelector(".info-screen")
        infoScreen.addEventListener("touchstart", (evt) => {
            console.log("screen touched")
            this.onInput(evt)
        })

        infoScreen.addEventListener("mousedown", (evt) => {
            console.log("screen clicked")
            this.onInput(evt)
        })

    }

    initCitationPhase() {
        this.camera.instance.position.set(0, 0, 10)
        // this.world.floatingObject.mesh.removeFromParent()
        this.world.fogs.material.uniforms.uZspeed.value = 1
        this.hideTapToSkip()
        this.world.citationCore.textGenerator.currentCitation.appear()
    }


}