import Experience from '../../Experience'
import * as THREE from "three"
import { TextGeometry } from 'three/examples/jsm/geometries/TextGeometry.js'
import EventEmitter from '../../Utils/EventEmitter'


export default class CustomText extends EventEmitter {
    constructor(str) {

        super()
        this.experience = new Experience()


        this.textParam = {
            font: this.experience.resources.items["Helvetica2"],
            size: 0.25,
            height: 0.05,
            curveSegments: 12,
            bevelEnabled: true,
            bevelThickness: 0.01,
            bevelSize: 0.01,
            bevelOffset: 0,
            bevelSegments: 5
        }


        this.timeBetweenChar = 25
        this.pauseTime = 1000
        this.charInbetweenSpace = 0.05
        this.spaceCharacterSize = 0.15
        this.lineBreakSpace = 0.45
        this.objScale = 1
        this.delimiterXtoLineBreak = 6

        this.material = new THREE.MeshBasicMaterial({})

        this.geomAlphabet = this.generateAlphabetGeometry()



        this.currentOffsetX = 0
        this.currentOffsetY = 0

        this.instance = this.generateText(str)
    }

    generateAlphabetGeometry() {
        let alphabet = "abcdefghijklmnopqrstuvwxyz"
        alphabet += "ABCDEFGHIJKLMNOPQRSTUVWXYZ"
        alphabet += "1234567890"
        alphabet += ",?;.:/!&é\"'(-è_çà)=’êâîùûœô/_-[]{}+-$€%£<>#"
        alphabet += "ÊÎïÏÈÉÀ@"

        const alphaArray = Array.from(alphabet)
        const geomObj = {}
        for (const char of alphaArray) {
            const charGeom = new TextGeometry(char, this.textParam)

            charGeom.computeBoundingBox()
            //re-center
            charGeom.translate(
                - charGeom.boundingBox.max.x * 0.5,
                0,
                - charGeom.boundingBox.max.z * 0.5)

            geomObj[char] = charGeom
        }
        return geomObj
    }

    splitBySection(str) {

        const charArray = Array.from(str)
        const sections = []

        let i = 0
        let wordStartI = 0
        while (i < charArray.length) {
            while (charArray[i] != "," 
            && charArray[i] != "\n" 
            && charArray[i] != "." 
            && charArray[i] != "?" 
            && charArray[i] != "!" 
            && i < charArray.length)
                i++
            while (charArray[i+1] == "." && i+1 < charArray.length)
                i++
            i++
            sections.push(str.slice(wordStartI, i))
            wordStartI = i
        }

        return sections
    }

    generateWord(str) {
        const newWord = new THREE.Group()

        let currentPosX = 0
        let previousChar
        for (const char of str) {
            if (char == "*" || char == "\n")
                continue
            const charGeom = this.geomAlphabet[char]
            if (!charGeom)
                continue
            const charMesh = new THREE.Mesh( charGeom , this.material)
            charMesh.position.x =
                currentPosX
                + charGeom.boundingBox.max.x
                + this.charInbetweenSpace
            currentPosX = charMesh.position.x + charGeom.boundingBox.max.x
            previousChar = charMesh
            newWord.add(charMesh)

        }
        const meshSize = new THREE.Box3().setFromObject(newWord)
        newWord.boxSize = meshSize


        return newWord
    }

    generateWords(str) {
        const sectionGroup = new THREE.Group()
        const words = str.split(" ")
        for (const word of words) {
            if (!word)
                continue
            const wordMesh = this.generateWord(word)
            if (this.currentOffsetX + wordMesh.boxSize.max.x > this.delimiterXtoLineBreak) {
                this.currentOffsetX = 0
                this.currentOffsetY -= this.lineBreakSpace
            }
            wordMesh.position.x = this.currentOffsetX
            wordMesh.position.y = this.currentOffsetY
            this.currentOffsetX = wordMesh.position.x + wordMesh.boxSize.max.x + this.spaceCharacterSize

            const lastChar = word.at(word.length - 1)
            if (lastChar == "."
            || lastChar == "?"
            || lastChar == "!") 
            {
                this.currentOffsetX = 0
                this.currentOffsetY -= this.lineBreakSpace * 2
            }
            sectionGroup.add(wordMesh)
        }
        const meshSize = new THREE.Box3().setFromObject(sectionGroup)
        sectionGroup.boxSize = meshSize
        return sectionGroup
    }

    generateText(str) {

        this.currentOffsetX = 0
        this.currentOffsetY = 0


        const textMeshFinal = new THREE.Group()

        const strSections = this.splitBySection(str)

        for (const subStr of strSections) {
            const sectionGroup = this.generateWords(subStr)
            textMeshFinal.add(sectionGroup)
        }

        textMeshFinal.position.set(
            - this.experience.scene.position.x,
            - this.experience.scene.position.y,
            - this.experience.scene.position.z,
        )
        const FinalSize = new THREE.Box3().setFromObject(textMeshFinal)
        textMeshFinal.position.x -= FinalSize.max.x * 0.5
        textMeshFinal.position.y -= FinalSize.min.y * 0.5


        for (const sectionGroup of textMeshFinal.children) {
            if (!sectionGroup instanceof THREE.Group)
                continue
            for (const word of sectionGroup.children) {
                for (const character of word.children) {
                    character.scale.set(0, 0, 0)
                }
            }
        }


        return textMeshFinal

    }

    reGenerate(str) {
        this.destroy()
        this.instance = this.generateText(str)
    }


    destroy() {

        this.instance.removeFromParent()
        this.instance.traverse((child) => {
            // Test if it's a mesh
            if (child instanceof THREE.Mesh) {
                // console.log("geometry disposed")
                // child.geometry.dispose()

                // Loop through the material properties
                // for (const key in child.material) {
                //     console.log("material disposed")

                //     const value = child.material[key]

                //     // Test if there is a dispose function
                //     if (value && typeof value.dispose === 'function') {
                //         value.dispose()
                //     }
                // }
            }
        })

    }

    skip()
    {
        for (const timeout of this.timeoutArray)
        {
            clearTimeout(timeout)
        }

        for (const sectionGroup of this.instance.children) {
            if (!sectionGroup instanceof THREE.Group)
                continue
            for (const word of sectionGroup.children) {
                for (const character of word.children) {
                    character.scale.set(1, 1, 1)
                }
            }
        }
        this.trigger("appearFinished")

    }

    appear() {
        let i = 0
        let sectionIterator = 0
        let charCount = 0
        let charDisplayed = 0
        this.timeoutArray = []
        this.trigger("startPlaying")
        for (const sectionGroup of this.instance.children) {
            if (!sectionGroup instanceof THREE.Group)
                continue
            for (const word of sectionGroup.children) {
                for (const character of word.children) {
                    charCount++
                    this.timeoutArray[i] = setTimeout(() => {
                        character.scale.set(1, 1, 1)
                        charDisplayed++
                        if (charDisplayed == charCount)
                        {
                            console.log("appear finished")
                            this.trigger("appearFinished")
                        }

                    }, i * this.timeBetweenChar + sectionIterator * this.pauseTime);
                    i++
                }
            }
            sectionIterator++
        }

    }


}