import * as THREE from 'three'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js'
import { FontLoader } from 'three/examples/jsm/loaders/FontLoader.js'
import EventEmitter from './EventEmitter.js'

export default class Resources extends EventEmitter
{
    constructor(sources)
    {
        super()

        this.sources = sources

        this.items = {}
        this.toLoad = this.sources.length
        this.loaded = 0

        this.setLoaders()
        this.startLoading()
    }

    setLoaders()
    {
        this.loaders = {}
        this.loaders.gltfLoader = new GLTFLoader()
        this.loaders.textureLoader = new THREE.TextureLoader()
        this.loaders.cubeTextureLoader = new THREE.CubeTextureLoader()
        this.loaders.fontLoader = new FontLoader()
        this.loaders.audioLoader = new THREE.AudioLoader()
    }

    startLoading()
    {
        // Load each source
        for(const source of this.sources)
        {
            if(source.type === 'gltfModel')
            {
                this.loaders.gltfLoader.load(
                    source.path,
                    (file) =>
                    {
                        this.sourceLoaded(source, file)
                    }
                )
            }
            else if(source.type === 'texture')
            {
                this.loaders.textureLoader.load(
                    source.path,
                    (file) =>
                    {
                        this.sourceLoaded(source, file)
                    }
                )
            }
            else if(source.type === 'cubeTexture')
            {
                this.loaders.cubeTextureLoader.load(
                    source.path,
                    (file) =>
                    {
                        this.sourceLoaded(source, file)
                    }
                )
            }
            else if(source.type === 'font')
            {
                this.loaders.fontLoader.load(
                    source.path,
                    (file) =>
                    {
                        this.sourceLoaded(source, file)
                    }
                )
            }
            else if(source.type === 'audio')
            {
                this.loaders.audioLoader.load(
                    source.path,
                    (file) =>
                    {
                        this.sourceLoaded(source, file)
                    }
                )
            }
        }
    }

    sourceLoaded(source, file)
    {
        this.items[source.name] = file

        this.loaded++

        let loadRatio = this.loaded / this.toLoad
        this.trigger("loadProgress", [loadRatio])

        if(this.loaded === this.toLoad)
        {

            this.reScale()
            this.generateFloatingModelsList()
            this.reprocess()

            this.trigger('ready')
        }
    }

    reScale()
    {
        let mesh

        mesh = this.items["heart"].scene
        mesh.traverse((child) => {
            if (child instanceof THREE.Mesh)
                child.geometry.center()
        })
        mesh.scale.set(0.04, 0.04, 0.04)

        mesh = this.items["chessBoard"].scene
        mesh.scale.set(11.5, 11.5, 11.5)

        mesh = this.items["dartBoard"].scene
        mesh.scale.set(4.5, 4.5, 4.5)

        mesh = this.items["hourGlass"].scene
        mesh.traverse((child) => {
            if (child instanceof THREE.Mesh)
                child.geometry.center()
        })

        mesh = this.items["halfOpenedBook"].scene
        mesh.scale.set(16, 16, 16)
      
    }

    generateFloatingModelsList()
    {
        this.floatingModels = []
        this.floatingModels.push(this.items["hourGlass"].scene)
        this.floatingModels.push(this.items["dartBoard"].scene)
        this.floatingModels.push(this.items["chessBoard"].scene)
        this.floatingModels.push(this.items["heart"].scene)
        this.floatingModels.push(this.items["halfOpenedBook"].scene)
    }

    reprocess()
    {
        for (const floatinModel of this.floatingModels)
        {
            floatinModel.traverse((child)=>{
                if (child instanceof THREE.Mesh)
                {
                    const hsl = {}
                    child.material.color.getHSL(hsl)
                    child.material.color.offsetHSL(0, - hsl.s * 0.8, 0)
                }
            })
        }

        
    }
}