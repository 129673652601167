import Experience from "../../Experience";
import * as THREE from "three"
import gsap from "gsap";

export default class Sounds {
    constructor() {
        this.experience = new Experience()
        this.resources = this.experience.resources
        this.soundDisabled = false
        this.listener = new THREE.AudioListener()
        this.music = new THREE.Audio(this.listener)
        this.thunderWind = new THREE.Audio(this.listener)
        this.whoosh = new THREE.Audio(this.listener)

        this.music.setBuffer(this.resources.items["bg-music"])
        this.music.setLoop(true)
        this.music.setVolume(0)

        this.thunderWind.setBuffer(this.resources.items["thunder-wind"])
        this.thunderWind.setLoop(true)
        this.thunderWind.setVolume(0)

        this.whoosh.setBuffer(this.resources.items["whoosh"])
        this.whoosh.playbackRate = 1.2
        this.whoosh.setVolume(1.4)

        console.log("master volume = ", this.listener.getMasterVolume())

        const musicButton = document.querySelector(".music-button")
        musicButton.addEventListener("mousedown", (evt) => { this.toggleMusic(evt) })
        musicButton.addEventListener("touchstart", (evt) => { this.toggleMusic(evt) })

    }

    toggleMusic(evt) {
        evt.stopPropagation()
        evt.preventDefault()
        if (this.debounce)
            return
        this.debounce = true
        setTimeout(() => {
            this.debounce = false
        }, 100);


        console.log("music bouton touched")

        if (!this.soundDisabled) {
            this.soundDisabled = true
            this.listener.setMasterVolume(0)
            this.music.pause()
            this.thunderWind.pause()
            document.querySelector("#is-stoped").classList.add("stoped")
        }
        else {
            this.soundDisabled = false
            this.listener.setMasterVolume(1)
            this.music.play()
            this.thunderWind.play()
            document.querySelector("#is-stoped").classList.remove("stoped")
        }


    }

    introWhoosh()
    {
        this.whoosh.playbackRate = 0.5
        this.whoosh.setVolume(1.8)
        this.skipWhoosh()
        this.whoosh.play()
    }

    launchWhoosh() {
        this.whoosh.setVolume(1.4)
        this.whoosh.playbackRate = 1.2
        this.skipWhoosh()
        this.whoosh.play()
    }

    skipWhoosh() {
        if (this.whoosh.isPlaying)
            this.whoosh.stop()
    }


    enableSounds() {
        const volume = { value: 0 }

        gsap.to(volume,
            {
                value: 1,
                duration: 5,
                ease: "power1.in",
                onUpdate: () => {
                    // console.log("music volume = ", volume.value)
                    this.music.setVolume(volume.value)
                    this.thunderWind.setVolume(volume.value * 1.2)
                }
            })

        const soundActivationInterval = setInterval(() => {

            if (!this.listener.context)
                this.listener.context = new THREE.AudioContext.getContext()

            this.listener.context.resume().then(() => {
                console.log("context resumed")
                clearInterval(soundActivationInterval)
            })
        }, 100);

        if (this.soundDisabled)
            return

        this.thunderWind.play()
        this.music.play()

    }
}