import gsap from "gsap";
import Experience from "../Experience";
import LoadingScreen from "./LoadingScreen";





export default class Intro {

    constructor() {

        this.experience = new Experience()
        this.camera = this.experience.camera
        this.loadingScreen = new LoadingScreen()
        this.currentTimeline = null
        this.initIntroParam()
    }

    initIntroParam() {
        this.experience.camera.instance.position.z = 200
    }

    skipIntro()
    {
        if (this.currentTimeline)
        {
            console.log("skipping intro ...")
            if (this.currentTimeline.progress() < 1)
                this.currentTimeline.progress(1)
        }
    }

    runIntro() {

        // this.skipIntro()
        // return

        this.experience.world.citationCore.phase = "intro"
        console.log("phase = ", this.experience.world.citationCore.phase)
        // setTimeout(() => {
        //     this.skipIntro()
        // }, 10);

        this.experience.time.resetTime()
        const tl = gsap.timeline({
            onComplete: () => {
                this.experience.world.citationCore.initCitationPhase()
            }
        })
        setTimeout(() => {
            this.experience.world.sounds.introWhoosh()
        }, 2000);
        this.currentTimeline = tl
        tl.to(this.camera.instance.position, {
            x: 0,
            y: 0,
            z: 10,
            duration: 3,
            ease: "power1.in"
        })
        tl.to(this.experience.world,{
            zOffset: 1000,
            duration: 4,
            ease: "power1.Out",
        })
        tl.to(this.experience.world.fogs.material.uniforms.uZspeed,{
            value: 1,
            duration: 2,
            ease: "power1.out"
        }, "<")

        


    }

}