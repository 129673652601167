import * as THREE from 'three'
import Experience from './Experience.js'
import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer'
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass'
import { UnrealBloomPass } from "three/examples/jsm/postprocessing/UnrealBloomPass";

export default class Renderer {
    constructor() {
        this.experience = new Experience()
        this.canvas = this.experience.canvas
        this.sizes = this.experience.sizes
        this.scene = this.experience.scene
        this.camera = this.experience.camera
        this.debug = this.experience.debug

        // Debug
        if (this.debug.active) {
            this.debugFolder = this.debug.ui.addFolder('Renderer')
        }

        this.setInstance()
        this.setPostProcess()
    }

    setPostProcess() {
        this.renderPass = new RenderPass(this.scene, this.camera.instance)
        this.bloomPass = new UnrealBloomPass(
            new THREE.Vector2(this.sizes.width, this.sizes.height),
            0.7,
            0.0,
            0.18
        )
        this.composer = new EffectComposer(this.instance)
        this.composer.addPass(this.renderPass)
        this.composer.addPass(this.bloomPass)

        if (this.debug.active) {
            this.debugFolder
                .add(this.bloomPass, 'strength')
                .name('strength')
                .min(0)
                .max(3)
                .step(0.001)
            this.debugFolder
                .add(this.bloomPass, 'radius')
                .name('radius')
                .min(0)
                .max(5)
                .step(0.001)
            this.debugFolder
                .add(this.bloomPass, 'threshold')
                .name('threshold')
                .min(0)
                .max(10)
                .step(0.001)
        }
    }

    setInstance() {
        this.instance = new THREE.WebGLRenderer({
            canvas: this.canvas,
            antialias: true
        })
        this.instance.physicallyCorrectLights = true
        this.instance.outputEncoding = THREE.sRGBEncoding
        this.instance.toneMapping = THREE.CineonToneMapping
        this.instance.toneMappingExposure = 0.4
        this.instance.shadowMap.enabled = true
        this.instance.shadowMap.type = THREE.PCFSoftShadowMap
        this.instance.setClearColor('#131112')
        this.instance.setSize(this.sizes.width, this.sizes.height)
        this.instance.setPixelRatio(Math.min(this.sizes.pixelRatio, 2))

        if (this.debug.active) {
            this.debugFolder
                .add(this.instance, 'toneMappingExposure')
                .name('toneMappingExposure')
                .min(0)
                .max(15)
                .step(0.001)
            this.debugFolder.add(this.instance, 'toneMapping', {
                No: THREE.NoToneMapping,
                Linear: THREE.LinearToneMapping,
                Reinhard: THREE.ReinhardToneMapping,
                Cineon: THREE.CineonToneMapping,
                ACESFilmic: THREE.ACESFilmicToneMapping
            })

            const color = {color: 0x211d20}

            this.debugFolder.addColor(color, 'color')
            .onChange( () => {
                this.instance.setClearColor(color.color)
            })
        }

    }

    resize() {
        this.instance.setSize(this.sizes.width, this.sizes.height)
        this.instance.setPixelRatio(Math.min(this.sizes.pixelRatio, 2))
    }

    update() {
        this.composer.render()
        // this.instance.render(this.scene, this.camera.instance)
    }
}