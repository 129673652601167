import Experience from '../Experience.js'
import Background from './Background.js'
import CitationCore from './Citation/CitationCore.js'
import FloatingObject from './Decors/FloatingObject.js'
import FloatingObjects from './Decors/FloatingObjects.js'
import Fogs from './Decors/Fogs'
import Stars from './Decors/Stars.js'
import TestFloor from './Decors/TestFloor.js'
import Environment from './Environment.js'
import Floor from './Floor.js'
import Fox from './Fox.js'
import * as THREE from "three"
import Sounds from './Decors/Sounds.js'
import Contact from './Decors/Contact.js'

export default class World {
    constructor() {
        this.experience = new Experience()
        this.scene = this.experience.scene
        this.resources = this.experience.resources
        this.camera = this.experience.camera
        this.zOffset = 0




        // Wait for resources
        this.resources.on('ready', () => {
            // Setup
            // this.floor = new Floor()
            // this.fox = new Fox()
            // this.background = new Background()
            // this.stars = new Stars()
            this.fogs = new Fogs()
            // this.testFloor = new TestFloor()
            this.citationCore = new CitationCore()
            // this.floatingObject = new FloatingObject()
            this.floatingObjects = new FloatingObjects()
            this.sounds = new Sounds()
            this.contact = new Contact()

            this.environment = new Environment()



            this.setCameraBasePos()
        })
    }


    setCameraBasePos() {
        // this.camera.instance.position.set(
        //     -0.0025891061207385935,
        //     0.053749102837626794,
        //     10.770195185347106
        // )

        this.camera.instance.position.set(
            -0.0025891061207385935,
            0.053749102837626794,
            500.770195185347106
        )

    }

    update() {
        if (this.fox)
            this.fox.update()
        if (this.background)
            this.background.update()
        if (this.fogs)
            this.fogs.update()
        if (this.floatingObjects)
            this.floatingObjects.update()
    }
}