import Experience from "../../Experience"
import * as THREE from "three"
import gsap from "gsap"




export default class FloatingObjects
{
    constructor()
    {

        this.experience = new Experience()
        this.time = this.experience.time
        this.debug = this.experience.debug
        this.items = this.experience.resources.items
        this.floatingModels = this.experience.resources.floatingModels

        this.objectGroup = new THREE.Group()
        this.param = {
            objCount: 35,
            zSpread: 3000,
            minRange: 15,
            maxRange: 30,
            scaleMin: 0.5,
            scaleVariable: 0.5
        }


        for (let i = 0; i < this.param.objCount; i++)
        {
            const pickedIndex = Math.floor(Math.random() * this.floatingModels.length)
            const mesh = this.floatingModels[pickedIndex].clone()

            const theta = Math.random() * Math.PI * 2
            const scale = (Math.random() * this.param.scaleVariable + this.param.scaleMin) * mesh.scale.x
            const rotationForce = new THREE.Vector3(
                Math.random(),
                Math.random(),
                Math.random()
            ).normalize()
            mesh.position.set(
                Math.cos(theta) * (Math.random() * (this.param.maxRange - this.param.minRange) + this.param.minRange),
                Math.sin(theta) * (Math.random() * (this.param.maxRange - this.param.minRange) + this.param.minRange),
                i / this.param.objCount * this.param.zSpread
            )
            mesh.originPosition = mesh.position.clone()
            mesh.scale.set(scale, scale, scale)
            mesh.rotationForce = rotationForce


            this.objectGroup.add(mesh)
        }
        this.experience.scene.add(this.objectGroup)


    }

    update()
    {
        for (const obj of this.objectGroup.children)
        {

            obj.position.z = ( obj.originPosition.z + this.experience.world.zOffset + this.time.elapsedSeconds * 50) % this.param.zSpread - this.param.zSpread

            obj.rotation.set(
                obj.rotationForce.x * this.time.elapsedSeconds,
                obj.rotationForce.y * this.time.elapsedSeconds,
                obj.rotationForce.z * this.time.elapsedSeconds,
            )


        }
    }


}