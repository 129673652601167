export default [
    {
        name: 'hourGlass',
        type: 'gltfModel',
        path: 'models/HourGlass.glb'
    },
    {
        name: 'dartBoard',
        type: 'gltfModel',
        path: 'models/Dartboard2.glb'
    },
    {
        name: 'chessBoard',
        type: 'gltfModel',
        path: 'models/Chessboard.glb'
    },
    {
        name: 'heart',
        type: 'gltfModel',
        path: 'models/Heart.glb'
    },
    {
        name: 'halfOpenedBook',
        type: 'gltfModel',
        path: 'models/halfOpenBook2.glb'
    },
    {
        name: 'noiseTexture',
        type: 'texture',
        path: 'textures/noiseTexture.png',
    },
    {
        name: 'smoke1',
        type: 'texture',
        path: 'textures/smokes/smoke2_1.png',
    },
    {
        name: 'Helvetica2',
        type: 'font',
        path: 'fonts/FreeSans_Medium_5.json'
    },
    {
        name: 'bg-music',
        type: 'audio',
        path: 'sounds/Art Of Silence_V2.mp3'
    },
    {
        name: 'whoosh',
        type: 'audio',
        path: 'sounds/whoosh-stereo-balance.mp3'
    },
    {
        name: 'thunder-wind',
        type: 'audio',
        path: 'sounds/thunder-and-wind.mp3'
    }
]