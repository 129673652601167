import * as THREE from 'three'
import Experience from '../Experience.js'

export default class Environment {
    constructor() {
        this.experience = new Experience()
        this.scene = this.experience.scene
        this.resources = this.experience.resources
        this.debug = this.experience.debug

        // Debug
        if (this.debug.active) {
            this.debugFolder = this.debug.ui.addFolder('environment')
        }

        this.setSunLight()
        // this.setEnvironmentMap()
        // this.setAmbientLight()
        this.setHemisphereLight()
        this.initFog()

    }

    initFog() {
        this.scene.fog = new THREE.Fog(
            0x120f13,
            0,
            450
        )

        if (this.debug.active) {
            this.debugFolder
                .add(this.scene.fog, 'near')
                .name('near')
                .min(0)
                .max(1000)
                .step(0.001)
            this.debugFolder
                .add(this.scene.fog, 'far')
                .name('far')
                .min(0)
                .max(1000)
                .step(0.001)
            const color = { color: 0x120f13 }
            this.debugFolder.addColor(color, 'color')
                .onChange(() => {
                    this.scene.fog.color = new THREE.Color(color.color)
                })
        }
    }

    setAmbientLight() {
        this.ambientLight = new THREE.AmbientLight(new THREE.Color("0xffffff"), 1.15)
        this.scene.add(this.ambientLight)
        // Debug
        if (this.debug.active) {
            this.debugFolder
                .add(this.ambientLight, 'intensity')
                .name('AmbientIntensity')
                .min(0)
                .max(10)
                .step(0.001)
        }
    }

    setHemisphereLight() {
        this.hemisphereLight = new THREE.HemisphereLight(0xffffff, 0x0, 1.888)
        this.scene.add(this.hemisphereLight)
        // Debug
        if (this.debug.active) {
            this.debugFolder
                .add(this.hemisphereLight, 'intensity')
                .name('hemisphereLight')
                .min(0)
                .max(10)
                .step(0.001)
        }
    }

    setSunLight() {
        this.sunLight = new THREE.DirectionalLight('#ffffff', 1)
        this.sunLight.castShadow = true
        this.sunLight.shadow.camera.far = 15
        this.sunLight.shadow.mapSize.set(1024, 1024)
        this.sunLight.shadow.normalBias = 0.05
        this.sunLight.position.set(0, 5, - 5)
        this.scene.add(this.sunLight)

        // Debug
        if (this.debug.active) {
            this.debugFolder
                .add(this.sunLight, 'intensity')
                .name('sunLightIntensity')
                .min(0)
                .max(10)
                .step(0.001)

            this.debugFolder
                .add(this.sunLight.position, 'x')
                .name('sunLightX')
                .min(- 5)
                .max(5)
                .step(0.001)

            this.debugFolder
                .add(this.sunLight.position, 'y')
                .name('sunLightY')
                .min(- 5)
                .max(5)
                .step(0.001)

            this.debugFolder
                .add(this.sunLight.position, 'z')
                .name('sunLightZ')
                .min(- 5)
                .max(5)
                .step(0.001)
        }
    }

    setEnvironmentMap() {
        this.environmentMap = {}
        this.environmentMap.intensity = 0.755
        this.environmentMap.texture = this.resources.items.environmentMapTexture
        this.environmentMap.texture.encoding = THREE.sRGBEncoding

        this.scene.environment = this.environmentMap.texture

        this.environmentMap.updateMaterials = () => {
            this.scene.traverse((child) => {
                if (child instanceof THREE.Mesh && child.material instanceof THREE.MeshStandardMaterial) {
                    child.material.envMap = this.environmentMap.texture
                    child.material.envMapIntensity = this.environmentMap.intensity
                    child.material.needsUpdate = true
                }
            })
        }
        this.environmentMap.updateMaterials()

        // Debug
        if (this.debug.active) {
            this.debugFolder
                .add(this.environmentMap, 'intensity')
                .name('envMapIntensity')
                .min(0)
                .max(4)
                .step(0.001)
                .onChange(this.environmentMap.updateMaterials)
        }
    }
}