import * as THREE from 'three'
import Experience from './Experience.js'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'
import gsap from 'gsap'


export default class Camera {
    constructor() {
        this.experience = new Experience()
        this.sizes = this.experience.sizes
        this.scene = this.experience.scene
        this.canvas = this.experience.canvas

        this.setInstance()
        // this.setControls()
        this.resize()
        this.setCameraWiggle()
    }

    setInstance() {
        this.instance = new THREE.PerspectiveCamera(35, 
            this.sizes.width / this.sizes.height, 
            0.1, 
            750)
        this.instance.position.set(6, 4, 8)
        // this.scene.add(this.instance)
    }

    setControls() {
        this.controls = new OrbitControls(this.instance, this.canvas)
        // this.controls.target = new THREE.Vector3(0, 0, -1000)
        this.controls.enableDamping = true
    }

    resize() {
        this.instance.aspect = this.sizes.width / this.sizes.height
        const minScreenX = 320
        const maxScreenX = 1920
        const ratio = (this.sizes.width - minScreenX) / (maxScreenX - minScreenX) 
        // this.instance.fov = 35 + 40 * (1 - ratio) 
        this.instance.fov = 35 * Math.min(Math.max(1 / this.instance.aspect, 1), 2)
        this.instance.updateProjectionMatrix()
    }

    setCameraWiggle()
    {
        const tl = gsap.timeline({
            repeat: -1,
            repeatRefresh: true
        })

        const tl2 = gsap.timeline({
            repeat: -1,
            repeatRefresh: true
        })

        tl.to(this.instance.position, {
            y: () => (Math.random() - 0.5) * 0.17,
            ease: "sine.inOut",
            duration: () => Math.random() * 1 + 1.2
        })
        tl2.to(this.instance.position, {
            x: () => (Math.random() - 0.5) * 0.17,
            ease: "sine.inOut",
            duration: () => Math.random() * 1 + 1.2
        })

    }

    update() {
        if (this.controls)
            this.controls.update()
    }
}