import gsap from "gsap"




export default class Contact {
    constructor() {

        this.modalDisplayed = false

        this.contactScreen = document.querySelector(".contact-screen")
        this.contactModal = document.querySelector(".contact-modal")
        const contactButton = document.querySelector(".contact-ref-button")
        const quitButton = document.querySelector(".mask-img-close")

        contactButton.addEventListener("mousedown", (evt) => { this.toggleContactModal(evt) })
        contactButton.addEventListener("touchstart", (evt) => { this.toggleContactModal(evt) })

        this.contactScreen.addEventListener("mousedown", (evt) => { this.toggleContactModal(evt, false) })
        this.contactScreen.addEventListener("touchstart", (evt) => { this.toggleContactModal(evt, false) })

        quitButton.addEventListener("mousedown", (evt) => { this.toggleContactModal(evt, false) })
        quitButton.addEventListener("touchstart", (evt) => { this.toggleContactModal(evt, false) })

        this.contactModal.addEventListener("mousedown", (evt) => { evt.stopPropagation() })
        this.contactModal.addEventListener("touchstart", (evt) => { evt.stopPropagation() })

        const tl = gsap.timeline({
            onReverseComplete: () => {
                this.contactScreen.classList.add("hidden")
            },
            onStart: () => {
                this.contactScreen.classList.remove("hidden")
            }
        })
        tl.fromTo(this.contactScreen,
            { opacity: 0 },
            {
                opacity: 1,
                ease: "power1.out",
                duration: 0.2
            })
        tl.fromTo(this.contactModal,
            {
                opacity: 0,
                y: -10,
            },
            {
                opacity: 1,
                ease: "power1.out",
                y: 0,
                duration: 0.3
            })
        tl.pause()
        this.timeline = tl

    }

    toggleContactModal(evt, forceState) {
        evt.stopPropagation()
        evt.preventDefault()
        if (this.debounce)
            return
        this.debounce = true
        setTimeout(() => {
            this.debounce = false
        }, 100);

        console.log("contact button clicked")

        //condition here reverted because reverted just after
        if (forceState === false)
            this.modalDisplayed = true
        if (forceState === true)
            this.modalDisplayed = false


        if (!this.modalDisplayed) {
            this.modalDisplayed = true
            this.contactScreen.classList.remove("hidden")
            this.timeline.play(0)
        }
        else {
            this.modalDisplayed = false
            this.timeline.reverse(1)
        }

    }


}